import { setupClickTracking } from './click-tracking.js';

window.addEventListener('load', () => {
  console.log('ADOBE LOADING...');
  const siteSection =
    location.pathname === '/'
      ? 'homepage'
      : location.pathname
          .replace(/-/g, ' ')
          .split('/')
          .filter((n) => n)
          .join('|');
  const pageName = `${location.host}${location.pathname}`.replace(/\/$/, '').replace(/\//g, ':');
  window.adobeDataLayer.push({
    event: 'pageLoad',
    reqData: {
      hitType: 'page view',
      requirementId: 'pvTCG0002',
    },
    pageData: {
      siteSection,
      URL: window.location.href,
      pageName,
      previousPage: document.referrer,
      pageTitle: document.title,
      site: 'The Cigna Group',
    },
  });
  setupClickTracking();
});

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    adobeDataLayer: any;
  }
}
