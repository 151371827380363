import { queryShadowRoot } from './shadow-query.js';

export const setupClickTracking = () => {
  console.log('click tracking setting up...');
  const allClickable = queryShadowRoot(document.body);

  const getSection = (path: Array<EventTarget>) => {
    const sections = ['TCG-HEADER', 'TCG-FOOTER'];
    const parentSection = Array.from(path).filter(
      (element) => element instanceof HTMLElement && sections.some((section) => element.tagName.includes(section)),
    );
    return parentSection.length ? (parentSection[0] as HTMLElement).tagName.toLowerCase() : 'body';
  };

  const getClosestHeading = (path: Array<EventTarget>) => {
    const componentContainer = Array.from(path).filter(
      (element) => element instanceof HTMLElement && element.classList.contains('iw_component'),
    );
    if (componentContainer.length) {
      const allHeadings = (componentContainer[0] as HTMLElement).querySelector('h1, h2');
      return allHeadings ? allHeadings.textContent?.trim() : 'The Cigna Group';
    }
    return 'The Cigna Group';
  };

  const sendAdobeClickEvent = (
    requirementId: string,
    customLinkText: string,
    linkLocation: string,
    destinationURL?: string,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const adobeData: any = {
      event: 'pageAction',
      reqData: {
        hitType: 'action',
        requirementId,
      },
      actionData: {
        customLinkText,
        linkLocation,
      },
    };
    if (destinationURL) {
      adobeData.actionData.destinationURL = destinationURL;
    }
    console.log('ADOBE DATA BEING SENT', adobeData);
    window.adobeDataLayer.push(adobeData);
  };

  allClickable.forEach((link: HTMLElement) => {
    link.addEventListener('click', (event) => {
      console.log('click!!', event);
      if (link.textContent?.trim().length || link.getAttribute('data-element-text')) {
        const linkText = link.textContent?.trim().length
          ? link.textContent.trim()
          : (link.getAttribute('data-element-text') as string);
        const path = event.composedPath();
        const linkPathname = location.pathname === '/' ? 'index' : location.pathname;
        const linkLocation = `${linkPathname}|${getSection(path)}|${getClosestHeading(path)}|${linkText}`;

        // External Links
        if (link instanceof HTMLAnchorElement && link.classList.contains('leaf-u-link--external')) {
          sendAdobeClickEvent('acTCG0009', linkText, linkLocation, `exit:${link.href}`);
        }
        // Download Links
        else if (
          link instanceof HTMLAnchorElement &&
          link.href &&
          ['.pdf', '.zip'].some((ext) => link.href.includes(ext))
        ) {
          sendAdobeClickEvent('acTCG0010', linkText, linkLocation, `download:${link.href}`);
        }
        // All Button / Link clicks
        else {
          sendAdobeClickEvent('acTCG0008', linkText, linkLocation);
        }
      }
    });
  });
};
