export const queryShadowRoot = (
  root: ShadowRoot | HTMLElement,
  maxDepth = 600,
  depth = 0,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): HTMLElement[] => {
  const matches: HTMLElement[] = [];

  if (depth >= maxDepth || (root instanceof HTMLElement && isHidden(root))) {
    return matches;
  }

  const traverseSlot = ($slot: HTMLSlotElement) => {
    const assignedNodes = $slot.assignedNodes().filter((node) => node.nodeType === 1);

    if (assignedNodes.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const $slotParent = assignedNodes[0].parentElement!;
      return queryShadowRoot($slotParent, maxDepth, depth + 1);
    }

    return [];
  };

  const children = <HTMLElement[]>Array.from(root.children || []);
  if ((root as HTMLElement).tagName && children.length === 0) {
    children.push(root as HTMLElement);
  }

  for (const $child of children) {
    if (isHidden($child)) {
      continue;
    }

    if (isFocusable($child)) {
      matches.push($child);
    }

    if (!isHidden($child)) {
      if ($child.shadowRoot != null) {
        matches.push(...queryShadowRoot($child.shadowRoot, maxDepth, depth + 1));
      } else if ($child.tagName === 'SLOT') {
        matches.push(...traverseSlot(<HTMLSlotElement>$child));
      } else {
        matches.push(...queryShadowRoot($child, maxDepth, depth + 1));
      }
    }
  }

  return matches.filter((c, index) => matches.indexOf(c) === index);
};

const isHidden = ($elem: HTMLElement): boolean => {
  return (
    $elem.hasAttribute('hidden') ||
    ($elem.hasAttribute('aria-hidden') && $elem.getAttribute('aria-hidden') !== 'false') ||
    $elem.style.display === `none` ||
    $elem.style.opacity === '0' ||
    $elem.style.visibility === `hidden` ||
    $elem.style.visibility === `collapse`
  );
};

const isFocusable = ($elem: HTMLElement): boolean => {
  if ($elem.getAttribute('tabindex') === '-1') return false;

  return (
    $elem.hasAttribute('tabindex') ||
    (($elem instanceof HTMLAnchorElement || $elem instanceof HTMLAreaElement) && $elem.hasAttribute('href')) ||
    $elem instanceof HTMLButtonElement ||
    $elem instanceof HTMLSelectElement ||
    $elem instanceof HTMLIFrameElement
  );
};
