import '@webcomponents/scoped-custom-element-registry';
import { registerIconSet } from '@cigna/dcws_leaf-web-components/utilities/icon-library.js';
import { loadComponents } from './components.js';
import { environment } from './environments/environment.js';
import { setInlineClasses } from './inline-class.js';
import { setLanguageToggleLinks } from './language-toggle.js';
import './analytics';

registerIconSet('tcg', (name) => `/static/svgs/tcg/${name}.svg`);
registerIconSet('logos', (name) => `/static/svgs/logos/${name}-logo.svg`);
registerIconSet('social', (name) => `/static/svgs/tcg/social/${name}.svg`);
registerIconSet('glyphs', (name) => `/static/svgs/glyphs/${name}.svg`);

loadComponents(environment.logger);

setLanguageToggleLinks();
setInlineClasses();
