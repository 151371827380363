const urlToHref = (url: URL) => url.toString().replace(url.origin, '');

const getLanguage = (lang: string, defaultLang = 'en') => {
  const locale = new Intl.Locale(lang);
  const language = locale?.language.toLowerCase();
  return language || defaultLang;
};

// get href from <link rel="alternate" hreflang="es-us" href="..">
const getAlternateHref = () => {
  const alternateLink = document.querySelector('link[rel="alternate"]');
  const hasAlternateHrefLang = Boolean(alternateLink?.getAttribute('hreflang'));
  if (hasAlternateHrefLang) {
    return alternateLink?.getAttribute('href');
  }
  return null;
};

const getHref = (hreflang: string) => {
  const targetHref = getAlternateHref();
  if (targetHref) {
    return urlToHref(new URL(targetHref));
  }

  const documentLanguage = document.documentElement.lang || navigator.language;
  const targetLanguage = getLanguage(hreflang);
  const url = new URL(document.location.href);

  if (targetLanguage !== documentLanguage) {
    const languagePrefixes: { [key: string]: string } = {
      en: '',
      es: 'es-us/',
    };

    if (targetLanguage === 'en') {
      const currentPrefix = languagePrefixes[documentLanguage];
      url.pathname = url.pathname.replace(new RegExp(`^/${currentPrefix}`), '/');
    } else {
      const targetPrefix = languagePrefixes[targetLanguage];
      url.pathname = `/${targetPrefix}${url.pathname.substring(1)}`;
    }
  }

  return urlToHref(url);
};

export const setLanguageToggleLinks = () => {
  const languageToggles = [
    document.getElementById('language-toggle'),
    document.getElementById('language-toggle-mobile'),
  ].filter(Boolean) as HTMLAnchorElement[];

  languageToggles.forEach((link) => {
    link.href = getHref(link.hreflang);
  });
};
