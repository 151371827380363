// Fix to adjust overflow issues with tabs caused by extra markup added by TeamSite

const WATCH_TAGS = 'leaf-tab';

const isOverflowing = (element: Element) => {
  const box = element.getBoundingClientRect();
  return box.left < 0 || box.right > document.documentElement.offsetWidth;
};

const adjustOverflow = (element: Element) => {
  const placeholder = element.closest('.iw_placeholder');
  placeholder?.classList.remove('leaf-u-overflow-hidden');
  if (isOverflowing(element)) {
    placeholder?.classList.add('leaf-u-overflow-hidden');
  }
};

export const setInlineClasses = () => {
  const watchElements = document.querySelectorAll(WATCH_TAGS);

  if (watchElements.length) {
    ['leaf-click', 'leaf-keydown'].forEach((eventName) => {
      window.addEventListener(eventName, (event) => {
        if (WATCH_TAGS.includes((event.target as Element)?.localName)) {
          adjustOverflow(event.target as Element);
        }
      });
    });

    window.addEventListener('resize', (_event) => {
      watchElements.forEach(adjustOverflow);
    });

    const ro = new ResizeObserver((entries) => {
      for (const entry of entries) {
        adjustOverflow(entry.target);
      }
    });

    watchElements.forEach((element) => ro.observe(element));
  }
};
